<template>
  <div class="admin-invite-wrapper">
    <article :class="`message ${ cardClass }`">
      <div class="message-header is-size-4">Admin Invite</div>
      <div class="message-body has-text-centered">
        <span class="icon mb-4">
          <i :class="`fas fa-2x ${ iconClass }`"></i>
        </span>
        <p class="is-size-4 mb-4">{{ cardMessage }}</p>
        <p v-if="adminInvitationStatus !== 'Pending'" class="is-size-4">
          You will be redirected to <a @click="toDashboardPage" class="has-text-blue">dashboard</a> in... {{ countdown }}
        </p>
      </div>
    </article>
  </div>
</template>
<script>
import { acceptAdminInvite } from '@api/addy-plus/account-administrators'

export default {
  data() {
    return {
      adminInvitationStatus: 'Pending',
      cardMessage: 'Verifying...',
      countdown: 10,
    }
  },
  computed: {
    invitationToken() {
      return this.$route.query.token
    },
    cardClass() {
      let klass
      switch (this.adminInvitationStatus) {
      case 'Success':
        klass = 'is-success'
        break
      case 'Failure':
        klass = 'is-danger'
        break
      default:
        klass = 'is-info'
      }
      return klass
    },
    iconClass() {
      let klass
      switch (this.adminInvitationStatus) {
      case 'Success':
        klass = 'fa-check-circle'
        break
      case 'Failure':
        klass = 'fa-times-circle'
        break
      default:
        klass = 'fa-pulse fa-spinner'
      }
      return klass
    },
  },
  mounted() {
    this.acceptInvitation()
  },
  methods: {
    acceptInvitation() {
      acceptAdminInvite(this.invitationToken).then((result) => {
        this.decrementCountdown()
        if (result.success) {
          this.adminInvitationStatus = 'Success'
          this.cardMessage = result.data.message
        } else {
          this.adminInvitationStatus = 'Failure'
          this.cardMessage = result.error
        }
      })
    },
    toDashboardPage() {
      this.$router.push('/addyPlus/dashboard')
    },
    decrementCountdown() {
      if (this.countdown <= 0) return this.toDashboardPage()
      setTimeout(() => {
        this.countdown--
        this.decrementCountdown()
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
.admin-invite-wrapper {
  height: 100%;
  position: relative;
  .message {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.is-success {
      .icon { color: #48c78e !important }
    }
    &.is-danger {
      .icon { color: #f14668!important }
    }
    .icon {
      i { color: inherit }
    }
  }
}
</style>
