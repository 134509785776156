import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Fetch addy plus account administrators
 * @param {String} accountId
 * @param {String} type
 * @return {Promise}
 */
export const fetchAllAdministrators = (accountId, type) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/administrators?type=${type}`))
}

/**
 * Update addy plus account administrator
 * @param {String} accountId
 * @param {String} adminId
 * @param {Object} payload
 * @return {Promise}
 */
export const updateAdministrator = (accountId, adminId, payload) => {
  return axios.patch(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/administrators/${adminId}`), payload)
}

/**
 * Create addy plus account administrator
 * @param {String} accountId
 * @param {Object} payload
 * @return {Promise}
 */
export const inviteAdministrator = (accountId, payload) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/administrators`), payload)
}

/**
 * Resend addy plus account administrator invite
 * @param {String} accountId
 * @param {String} adminId
 * @return {Promise}
 */
export const resendAdministratorInvite = (accountId, adminId) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/administrators/${adminId}/resend_invite`))
}

/**
 * Delete addy plus account administrator
 * @param {String} accountId
 * @param {String} adminId
 * @return {Promise}
 */
export const deleteAdministrator = (accountId, adminId) => {
  return axios.delete(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/administrators/${adminId}`))
}

/**
 * Fetch addy plus account administrator
 * @param {String} accountId
 * @param {String} adminId
 * @return {Promise}
 */
export const fetchAdministrator = (accountId, adminId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/administrators/${adminId}`))
}

/**
 * Fetch addy plus account administrator corporation permissions
 * @param {String} accountId
 * @param {String} adminId
 * @param {String} corporationId
 * @return {Promise}
 */
export const fetchAdminCorporationPermissions = (accountId, adminId, corporationId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/administrators/${adminId}/permissions`), { params: { corporation_id: corporationId } })
}

/**
 * Update addy plus account administrator corporation permissions
 * @param {String} accountId
 * @param {String} adminId
 * @param {Object} payload
 * @return {Promise}
 */
export const updateAdminCorporationPermissions = (accountId, adminId, payload) => {
  return axios.put(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/administrators/${adminId}/permissions`), payload)
}

/**
 * Bulk update addy plus dealer administrator permissions
 * @param {String} accountId
 * @param {String} adminId
 * @param {Object} payload
 * @return {Promise}
 */
export const updateDealerAdminPermissions = (accountId, adminId, payload) => {
  return axios.put(getImbyRoute(`/api/addy_plus/v1/accounts/${accountId}/administrators/${adminId}/permissions/delegate_entity`), payload)
}

/**
 * Accept addy plus account admin invite
 * @param {String} token
 * @return {Promise}
 */
export const acceptAdminInvite = (token) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/accounts/administrators/accept_invitation`), { token: token })
}
